// Team section
.team-section {
  .avatar {

    // stylelint-disable selector-no-qualifying-type
    img {
      max-width: $team-section-avatar-max-width;
      height: auto;
      &.rounded-circle {
        max-width: $team-section-avatar-circle-max-width;
      }
    }
  }
}
