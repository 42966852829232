// Set defaults for vendor variables
$aria-select-height: 2.9rem !default;
$aria-select-height: $material-select-wrapper-input-dropdown-height;
$aria-select-line-height: 2.9rem !default;
$aria-select-line-height: $material-select-wrapper-input-dropdown-line-height;
$aria-select-bottom-margin: .94rem !default;
$aria-select-bottom-margin: $material-select-wrapper-input-dropdown-margin;
$aria-select-list-min-width: 6.25rem !default;
$aria-select-list-min-width: $material-select-dropdown-content-min-width;
$aria-select-list-max-height: 4.625rem !default;
$aria-select-list-max-height: $material-select-dropdown-content-max-height;
$aria-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12) !default;
$aria-box-shadow: $z-depth-1;

.aria-select {
    button {
        -moz-appearance: none;
        -webkit-appearance: none;
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid color('nijmegen-forms', 'unfocussed-gray');
        cursor: pointer;
        display: inline-block;
        height: $aria-select-height;
        line-height: $aria-select-line-height;
        margin: 0 0 $aria-select-bottom-margin;
        outline: none;
        padding: 0 25px 0 0;
        text-align: left;
        text-decoration: none;
        text-overflow: ellipsis;
        width: 100%;

        &::after,
        &[aria-expanded='true']::after { // chevrons
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            color: color('nijmegen-primary', 'base');
            font-feature-settings: 'liga';
            font-size: 1.5rem;
            position: absolute;
            right: 15px;
            top: 1rem;
            visibility: visible;
        }

        &::after {
            @include mdi('chevron-down');
        }

        &[aria-expanded='true']::after {
            @include mdi('chevron-up');
        }
    }

    label {
        font-weight: $font-weight-semibold;
        top: $form-label-top-offset;
        transform: none;
    }

    .hidden {
        display: none;
    }
}

.aria-select-list {
    background-color: #fff;
    box-shadow: $aria-box-shadow;
    list-style-type: none;
    margin: 0;
    max-height: $aria-select-list-max-height;
    min-width: $aria-select-list-min-width;
    opacity: 0;
    overflow-y: auto;
    padding-left: 0;
    position: absolute;
    user-select: none;
    width: 100%;
    will-change: width, height;
    z-index: 999;

    [role='option'] {
        border-bottom: $form-drop-down-bottom-border;
        clear: both;
        color: $body-color;
        cursor: pointer;
        display: block;
        font-size: to-rem(16);
        line-height: $material-select-dropdown-content-li-line-height;
        padding: 1rem;
        text-align: left;
        text-transform: none;
        width: 100%;

        &.active {
            background-color: transparent;
            color: color('nijmegen-primary', 'base');
        }

        &.selected {
            background-color: transparent;
            color: color('nijmegen-primary', 'base');
        }

        &.focused {
            background: rgba($focus-color, .2);
        }

        &:last-child {
            border-bottom: 0;
        }
    }
}
