.navbar-skiplinks {
    a {
        background: #fff;
        border-bottom: 1px solid color('nijmegen-primary', 'base');
        color: color('nijmegen-brand', 'darken-1');
        font-size: 1.125rem;
        left: 0;
        line-height: 1.5rem;
        padding: .8125rem;
        position: fixed;
        right: 0;
        text-align: center;
        text-transform: uppercase;
        top: 0;
        z-index: 99;

        &:not(:focus){
            @include sr-only;
        }

        &:focus {
            outline: none;
        }
    }
}
