button {
    cursor: pointer;
}

.btn {
    display: block;
    font-size: to-rem(18);
    font-weight: $font-weight-semibold;
    letter-spacing: .7px;
    width: calc(100% - 6px - 6px); // MDB sets left and right margins of 6px each

    @media #{$medium-and-up} {
        display: inline-block;
        width: 250px;
    }
}

.btn.btn-primary {
    background-color: color('nijmegen-primary', 'base');

    &:hover {
        background-color: color('nijmegen-primary', 'lighten-1');
    }

    &:focus {
        @include tab-focus;
        background-color: color('nijmegen-primary', 'darken-1');
        color: #fff;
    }

    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:not([disabled]):not(.disabled):active {
        background-color: color('nijmegen-primary', 'darken-3');
    }

    &:disabled,
    &:disabled:hover {
        background-color: color('nijmegen-grey', 'base');
        box-shadow: none;
    }
}

.btn.btn-secondary {
    background-color: color('white', 'base');
    border: 1px solid color('nijmegen-grey', 'darken-1');
    color: color('nijmegen-primary', 'base');

    &:hover,
    &:focus {
        background-color: color('nijmegen-grey', 'lighten-1');
        border-color: color('nijmegen-grey', 'darken-1');
        color: color('nijmegen-primary', 'darken-2');
    }

    &:focus {
        @include tab-focus;
    }

    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:not([disabled]):not(.disabled):active {
        background-color: color('nijmegen-grey', 'lighten-1');
        color: #212121;
    }

    &:disabled,
    &:disabled:hover {
        @extend .z-depth-1;
        background-color: color('white', 'base');
        border-color: color('nijmegen-grey', 'darken-1');
        color: color('nijmegen-grey', 'base');
    }
}

.btn-info {
    background-color: color('white', 'base');
    border: 1px solid transparent;
    box-shadow: none;
    color: color('nijmegen-primary', 'base');

    &:hover,
    &:focus {
        background-color: color('nijmegen-grey', 'lighten-1');
        border-color: transparent;
        box-shadow: none;
        color: color('nijmegen-primary', 'darken-2');
    }

    &:focus {
        @include tab-focus;
        background-color: color('white', 'base');
    }

    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:not([disabled]):not(.disabled):active {
        background-color: color('white', 'base');
        border-color: transparent;
        box-shadow: none;
        color: $body-color;
    }

    &:disabled,
    &:disabled:hover {
        background-color: color('white', 'base');
        border-color: transparent;
        color: color('nijmegen-grey', 'base');
    }
}
