.stepper-horizontal {
    &:before {
        background-color: color('nijmegen-grey', 'lighten-1');
        content: '';
        height: 1px;
        position: absolute;
        top: 38px;
        width: 100%;

        @media (max-width: $medium-screen-portrait) {
            height: calc(100% - 40px);
            left: 2.4rem;
            top: 3.75rem;
            width: 1px;
        }
    }

    @media (max-width: $small-screen-portrait) {
        margin-bottom: to-rem(46);
        margin-top: to-rem(46);
    }

    .nextBtn, .backBtn {
        @media (max-width: $small-screen-portrait) {
            display: none;
        }
    }
}

.step-content {
    left: 0;
    position: absolute;
    top: 80px;
    width: 100%;

    @media (max-width: $medium-screen-portrait) {
        position: static;
        top: 0;
    }

    @media (min-width: #{$small-screen-portrait}) and (max-width: #{$medium-screen-portrait}) {
        margin-left: 4rem;
        width: calc(100% - 6rem);
    }
}

.stepper-horizontal li.active a.step-link .circle,
.stepper-horizontal li.completed a.step-link .circle {
    background-color: color('nijmegen-primary', 'base');
    color: color('white', 'base');
}

.stepper-horizontal li {
    align-items: initial;
    flex: 0 1 auto;
    position: static;

    &:hover,
    &:not(:last-child):after,
    &:not(:first-child):before {
        background-color: transparent;
    }

    &:first-child a.step-link:before,
    &:last-child a.step-link:after {
        background-color: color('white', 'base');
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        width: 50%;
    }

    &:first-child a.step-link:before {
        left: -50%;
    }

    &:last-child a.step-link:after {
        right: -50%;

        @media (max-width: $medium-screen-portrait) {
            right: 50%;
            top: 100%;
        }
    }

    a.step-link {
        background: color('white', 'base');
        font-size: to-rem(16);
        margin: .9rem;
        padding: .6rem;
        position: relative;
        text-align: center;
        z-index: 10;

        @media (max-width: $medium-screen-portrait) {
            text-align: left;
        }

        @media (max-width: $small-screen-portrait) {
            display: none;
        }

        .circle {
            border-radius: 50%;
            display: inline-block;
            height: 28px;
            margin-right: .5rem;
            text-align: center;
            width: 28px;

            &, .mdi {
                font-size: to-rem(14);
                font-weight: $font-weight-bold;
                line-height: 28px;
            }
        }

        .label {
            color: $body-color;
            font-weight: $font-weight-normal;
            margin-top: 0;
            white-space: nowrap;
        }

        &:hover {
            background-color: color('nijmegen-grey', 'lighten-1');
        }
    }

    &.active,
    &.completed {
        a.step-link:hover .circle {
            background-color: color('nijmegen-primary', 'darken-2');
        }
    }

    &:not(.active):not(.completed) {
        a.step-link .circle {
            background-color: color('nijmegen-grey', 'base');
            color: color('nijmegen-grey', 'darken-4');
        }
    }
}

.stepper-mobile-summary,
.stepper-mobile-navigation {
    background: color('nijmegen-grey', 'lighten-1');
    height: to-rem(46);
    left: 0;
    line-height: to-rem(46);
    padding: 0 to-rem(16);
    position: fixed;
    width: 100%;
    z-index: 20;

    @media (min-width: $small-screen-portrait) {
        display: none;
    }
}

.stepper-mobile-summary {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .16), 0 1px 4px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .16), 0 1px 4px 0 rgba(0, 0, 0, .12);
    top: 0;
}

.stepper-mobile-navigation {
    bottom: 0;
    font-size: to-rem(20);

    span {
        vertical-align: middle;
    }
}
