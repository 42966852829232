.facets-advanced .facets {
    &__group{
        &:not(.expanded) {

            .facets__list {
                display: none;
            }
        }
    }

    &__group-button {
        background: 0;
        border: 0;
        cursor: pointer;
        padding: 0;

        &[aria-expanded='false'] .facets__group-title::before {
            @include mdi('chevron-down');
        }

        &:focus {
            @include tab-focus;
        }
    }

    &__group-title{
        @include web-font-rendering-support;

        font-family: 'Oranda', sans-serif;
        margin-bottom: to-rem(8);
        position: relative;

        &::before {
            @include mdi('chevron-up');
            left: -28px;
            line-height: 100%;
            position: absolute;
            top: 5px;

            @media #{$medium-and-down} {
                left: -26px;
                top: 0;
            }
        }
    }

    &__checkbox + label {
        color: color('nijmegen-primary', 'base');
        padding-left: 30px;

        &::before {
            height: 1rem;
            top: 1px;
            width: 1rem;
        }
    }

    &__checkbox:checked + label::before {
        height: 1rem;
        left: 0;
        top: 0;
        width: 8px;
    }
}
