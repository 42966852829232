.sticky.widget {
    background-color: color('nijmegen-brand', 'base');
    padding: 20px;

    .title {
        font-family: 'Oranda', sans-serif;
        font-size: to-rem(28);
        line-height: 1.1;
        margin-bottom: 22px;
    }

    p {
        color: color('white', 'base');
    }
}
