// Sidenav background variants
.sn-bg-1 {
  background-image: url("https://mdbootstrap.com/img/Photos/Others/sidenav1.jpg");
}

.sn-bg-2 {
  background-image: url("https://mdbootstrap.com/img/Photos/Others/sidenav2.jpg");
}

.sn-bg-3 {
  background-image: url("https://mdbootstrap.com/img/Photos/Others/sidenav3.jpg");
}

.sn-bg-4 {
  background-image: url("https://mdbootstrap.com/img/Photos/Others/sidenav4.jpg");
}

// Sidenav
.side-nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: $sidenav-width;
  height: 100%;
  padding: 0;
  padding-bottom: $sidenav-padding-bottom;
  margin: 0;
  overflow: hidden;
  list-style-type: none;
  background-color: $sidenav-background-color;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: $z-depth-1;
  transform: translateX(-100%);
  backface-visibility: hidden;
  will-change: transform;
  @extend .white-text !optional;

  &.wide {
    width: $sidenav-width;
    transition-timing-function: linear, linear, ease;
    transition-duration: .5s, .5s, .55s;
    transition-property: top, bottom, width;

    .logo-wrapper {
      &.sn-ad-avatar-wrapper {
        height: 5rem;
        padding: 19px 10px;
        border-bottom: 1px solid $sidenav-logo-wrapper-border-bottom-color;
        overflow: hidden;

        a {
          line-height: 2.6rem;
          color: #fff;

          span {
            padding-left: .7rem;
            margin-top: -1rem;
          }

          img {
            max-width: 2.5rem;
            padding: 0;
          }
        }
      }
    }

    .collapsible {
      a {
        padding-left: 23px;
        transition: all .3s ease-in-out;

        .sv-normal {
          opacity: 1;
          transition: all .1s linear;
        }

        .sv-slim {
          display: none;
          opacity: 0;
          transition: all .1s linear;
        }
      }

      .sv-slim-icon {
        width: 30px;
        height: 36px;
        padding-left: 0;
        margin-right: 0;
        text-align: left;
      }
    }

    .collapsible-body a {
      padding-left: $sidenav-collapsible-body-a-pl;
    }

    .fa-angle-down {
      &.rotate-icon {
        display: block;
        opacity: 1;
      }
    }

    &.slim {
      right: 3.75rem;
      width: 3.75rem;
      transition-timing-function: linear, linear, ease;
      transition-duration: .5s, .5s, .55s;
      transition-property: top, bottom, width;

      .collapsible {
        a {
          padding-left: 23px;
          transition: all .3s ease-in-out;

          .sv-normal {
            opacity: 0;
            transition: all .1s linear;
          }

          .sv-slim {
            display: block;
            opacity: 1;
            transition: all .1s linear;
          }
        }

        .sv-slim-icon {
          width: 30px;
          height: 36px;
          padding-left: 0;
          margin-right: 0;
          text-align: left;
        }
      }

      .fa-angle-down {
        &.rotate-icon {
          display: none;
          opacity: 0;
        }
      }
    }
  }

  > ul {
    max-height: 100vh;
  }

  ul {
    padding-left: 0;
    list-style: none;
    list-style-type: none;

    li {
      padding: 0;
    }
  }

  &.right-aligned {
    right: 0;
    left: auto;
    transform: translateX(100%);
  }

  // Light version
  &.side-nav-light {
    background-color: $sidenav-light-bgc;

    .logo-wrapper {
      border-bottom: 1px solid $sidenav-light-border-bottom-color;

      &.sn-ad-avatar-wrapper {
        color: $sidenav-light-color;
      }
    }

    .about {
      border-bottom: 1px solid $sidenav-light-border-bottom-color;

      p {
        color: $sidenav-light-color;
      }
    }

    .social {
      border-bottom: 1px solid $sidenav-light-border-bottom-color;

      .fas,
      .fab,
      .far {
        color: $sidenav-light-color;
      }
    }

    .search-form {
      input[type="text"] {
        color: $sidenav-light-color;
        border-bottom-color: $sidenav-light-border-bottom-color;

        @include placeholder {
          color: $sidenav-light-color;
        }
      }
    }

    .collapsible {
      a {
        color: $sidenav-light-color;
      }
    }

    .collapsible-body {
      a {
        background-color: $sidenav-light-collapsible-body-a-bgc;
      }
    }

    .collapsible li .collapsible-header {
      &:hover {
        background-color: $sidenav-light-collapsible-header-hover-bgc;
      }

      &.active {
        color: $sidenav-light-collapsible-header-active-color;
        background-color: transparent;
      }
    }
  }

  // Fixed side-nav shown
  &.fixed {
    position: fixed; // Right Align
    left: 0;
    transform: translateX(0);

    &.right-aligned {
      right: 0;
      left: auto;
    }
  }

  // Fixed sideNav hide on smaller
  @media #{$hide-sidenav} {
    &.fixed {
      transform: translateX(-105%);

      &.right-aligned {
        transform: translateX(105%);
      }
    }
  }

  // Collapsible menu
  .collapsible {
    padding: 0;
    margin: 0;
    margin-top: $sidenav-collapsible-mt;

    li {
      a {
        &:hover {
          background-color: $collapsible-hover-bgc;
        }
      }
    }

    > li {
      border-radius: $collapsible-li-border-radius;

      a.collapsible-header {
        &:hover {
          background-color: $collapsible-header-hover-bgc;
        }

        &.active {
          background-color: $collapsible-header-active-bgc;
        }
      }
    }

    ul {
      padding: 0;
      list-style-type: none;
    }

    a {
      height: $sidenav-collapsible-a-height;
      font-size: $sidenav-collapsible-a-font-size;
      font-weight: 300;
      line-height: $sidenav-collapsible-a-line-height;
      color: $white-base;

      &.active,
      &:hover {
        border-radius: $collapsible-li-border-radius;
      }
    }

    .fas,
    .fab,
    .far {
      margin-right: $sidenav-fa-margin-right;
      font-size: $sidenav-collapsible-a-font-size;
    }
  }

  .collapsible-body a {
    height: $sidenav-collapsible-a-height;
    padding-left: $sidenav-collapsible-body-a-pl;
    line-height: $sidenav-collapsible-a-height;
    background-color: $sidenav-collapsible-body-a-bgc;
  }

  a {
    display: block;
    height: $sidenav-a-height;
    padding-left: $sidenav-padding-left;
    font-size: $sidenav-a-font-size;
    line-height: $sidenav-a-line-height;
  }

  // Manu icon
  .fa-angle-down {
    &.rotate-icon {
      position: absolute;
      top: $sidenav-rotate-icon-top;
      right: 0;
      margin-right: $sidenav-rotate-icon-mr;
    }
  }

  // Mask and image
  .sidenav-bg {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: $sidenav-bg-width;
    background-attachment: fixed;

    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 100%;
      padding-bottom: 99999px;
      margin-bottom: -99999px;
      content: "";
    }
  }

  // SideNav logo
  .logo-wrapper {
    height: $sidenav-logo-wrapper-height;
    border-bottom: 1px solid $sidenav-light-border-bottom-color;

    a {
      width: $sidenav-logo-wrapper-width;
      height: $sidenav-logo-wrapper-height;
      padding: 0;
    }

    img {
      height: auto;
      padding-top: $sidenav-logo-wrapper-img-py;
      padding-right: $sidenav-logo-wrapper-img-px;
      padding-bottom: $sidenav-logo-wrapper-img-py;
      padding-left: $sidenav-logo-wrapper-img-px;
    }

    @media (max-height: $medium-screen) {
      height: $sidenav-logo-wrapper-medium-img-height;

      a {
        height: $sidenav-logo-wrapper-medium-img-height;
      }

      img {
        padding-top: $sidenav-logo-wrapper-medium-img-py;
        padding-bottom: $sidenav-logo-wrapper-medium-img-py;
      }
    }

    &.sn-avatar-wrapper {
      padding-top: $sidenav-sn-avatar-wrapper-py;
      padding-right: $sidenav-sn-avatar-wrapper-px;
      padding-bottom: $sidenav-sn-avatar-wrapper-py;
      padding-left: $sidenav-sn-avatar-wrapper-px;

      img {
        max-width: $sidenav-sn-avatar-wrapper-max-width;
        padding: 0;
        margin: 0;
        box-shadow: $z-depth-1;
      }

      @media only screen and (max-height: $medium-screen) {
        padding-right: $sidenav-sn-avatar-wrapper-medium-px;
        padding-left: $sidenav-sn-avatar-wrapper-medium-px;

        img {
          max-width: $sidenav-sn-avatar-wrapper-medium-max-width;
        }
      }
    }

    &.sn-ad-avatar-wrapper {
      height: auto;
      margin-bottom: 0;

      img {
        float: left;
        max-width: $sidenav-ad-avatar-wrapper-img-max-width;
        padding: $sidenav-ad-avatar-wrapper-img-padding;
      }

      p {
        padding-top: $sidenav-ad-avatar-wrapper-p-padding;
        padding-bottom: $sidenav-ad-avatar-wrapper-p-padding;
        margin: 0;
        font-size: $sidenav-ad-avatar-wrapper-p-font-size;
      }
    }
  }

  // About
  .about {
    padding: $sidenav-about-padding;
    border-bottom: 1px solid $sidenav-logo-wrapper-border-bottom-color;

    p {
      margin-bottom: 0;
      text-align: center;
    }
  }

  // Social
  .social {
    padding-top: 0;
    text-align: center;
    border-bottom: 1px solid $sidenav-logo-wrapper-border-bottom-color;

    li {
      display: inline-block;
      padding-top: $sidenav-social-li-padding;
      padding-bottom: $sidenav-social-li-padding;
      margin: 0;
    }

    a {
      padding: 0;
      margin: 0;
    }

    .fas,
    .fab,
    .far {
      padding-right: $sidenav-social-li-padding;
      padding-left: $sidenav-social-li-padding;
      font-size: $sidenav-social-fa-font-size;
      color: $sidenav-social-fa-color;
      transition: $sidenav-social-fa-transition;

      &:hover {
        color: $sidenav-social-fa-color-hover;
        transition: $sidenav-social-fa-transition;
      }
    }
  }

  // Search form
  .search-form {
    padding: 0;

    input[type="text"] {
      padding-left: $sidenav-search-form-input-padding-left;
      font-weight: 300;
      border-bottom: 1px solid $sidenav-logo-wrapper-border-bottom-color;

      @include placeholder {
        color: #fff;
      }
    }

    .form-control {
      @extend .white-text !optional;
      margin-bottom: 0;
    }
  }
}

// Touch interaction
.drag-target {
  position: fixed;
  top: 0;
  z-index: 998;
  width: $drag-target-width;
  height: 100%;
}

#sidenav-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 997;
  height: 120vh;
  background-color: $sidenav-overlay;
  will-change: opacity;
}

// Fix to input in sidenav, when you click on mobile ( android , chrome 73+ )
.transform-fix-input {
  transform: translateX(0);
}
