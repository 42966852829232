// Globals
// Full palette of colors
@each $color_name, $color in $mdb-colors-1 {
  @each $color_type, $color_value in $color {
    @if $color_type == "base" {
      .#{$color_name} {
        background-color: $color_value;
      }
      .#{$color_name}-text {
        color: $color-value;
      }
      .rgba-#{$color_name}-slight,
      .rgba-#{$color_name}-slight:after {
        background-color: rgba($color_value, .1);
      }
      .rgba-#{$color_name}-light,
      .rgba-#{$color_name}-light:after {
        background-color: rgba($color_value, .3);
      }
      .rgba-#{$color_name}-strong,
      .rgba-#{$color_name}-strong:after {
        background-color: rgba($color_value, .7);
      }
    }
    @else {
      @if $enable_full_palette {
        .#{$color_name}.#{$color_type} {
          background-color: $color_value;
        }
      }
    }
  }
}

// Stylish color
@each $color_name, $color_value in $stylish-rgba {
  .#{$color_name} {
    background-color: $color_value;
  }
}

// Material colors palette
@each $color_name, $color in $material-colors {
  .#{$color_name} {
    background-color: $color;
  }
}

// Basic gradients
@each $name, $val in $gradients {
  @include make-gradient($name, $val);
}
@each $name, $val in $gradients-rgba {
  @include make-gradient-rgba($name, $val);
}

.dark-grey-text {
  color: #4f4f4f;
  &:hover,
  &:focus {
    color: #4f4f4f;
  }
}

// Shadow on hover
.hoverable {
  box-shadow: none;
  transition: $transition-hoverable;
  &:hover {
    box-shadow: $z-depth-2;
    transition: $transition-hoverable;
  }
}

// Shadows
.z-depth-0 {
  box-shadow: none;
}
.z-depth-1 {
  box-shadow: $z-depth-1;
}
.z-depth-1-half {
  box-shadow: $z-depth-1-half;
}
.z-depth-2 {
  box-shadow: $z-depth-2;
}
.z-depth-3 {
  box-shadow: $z-depth-3;
}
.z-depth-4 {
  box-shadow: $z-depth-4;
}
.z-depth-5 {
  box-shadow: $z-depth-5;
}

// Disabled cursor
.disabled,
:disabled {
  pointer-events: none;
}

// Links
a {
  color: $link-color;
  text-decoration: none;
  cursor: pointer;
  transition: $transition-basic;
  &:hover {
    color: $link-hover-color;
    text-decoration: none;
    transition: $transition-basic;
  }
  &.disabled,
  &:disabled {
    &:hover {
      color: $link-color;
    }
  }
}

a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

// Divider
.divider-new {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: $divider-margin-y;
  margin-bottom: $divider-margin-y;
  > h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
  }
  &:before,
  &:after {
    flex: 1;
    height: 1.5px;
    height: $divider-height;
    content: "";
    background: #c6c6c6;
  }
  &:before {
    margin: 0 $divider-margin-x 0 0;
  }
  &:after {
    margin: 0 0 0 $divider-margin-x;
  }
}
