// Function to convert the pt from zeplin to rem, the by framework used format.
@function to-rem($pt) {
    // MDB v4.5 uses 1rem as $font-size-base thus assuming 16px will be the default size
    @return ($pt / (16px / 1px)) + rem;
}

@function color($color, $type) {
    @if map-has-key($colors, $color) {
        $curr-color: map-get($colors, $color);
        @if map-has-key($curr-color, $type) {
            @return map-get($curr-color, $type);
        }
    }
    @warn 'Unknown `#{name}` in $colors.';
    @return null;
}

@mixin mdi($icon) {
    @include web-font-rendering-support;
    content: mdi($icon);
    direction: ltr;
    display: inline-block;
    font-family: '#{$mdi-font-name}', sans-serif;
    font-size: 24px;
    font-style: normal; // optimizelegibility throws things off #1094
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1;
    text-rendering: auto;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
}

@mixin web-font-rendering-support {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: ialiased;
    font-feature-settings: 'liga';
    text-rendering: optimizeLegibility;
}

@mixin tab-focus() {
    outline: 3px auto $focus-color;
}

@mixin tab-focus-minimal() {
    outline: 2px solid $focus-color ;
    transition: none;
}

@mixin tab-focus-minimal-inverted() {
    outline: 2px solid $light-focus-color ;
}

@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        content: '';
        display: block;
        padding-top: ($height / $width) * 100%;
        width: 100%;
    }

    > .content {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;
    }
}

@mixin sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
