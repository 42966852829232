// Ecommerce Pro Cards
// Collection card
.collection-card {
  .stripe {
    position: absolute;
    bottom: $collection-card-stripe-bottom;
    width: 100%;
    padding: $collection-card-stripe-padding;
    text-align: center;
    &.dark {
      background-color: $collection-card-stripe-dark-bgc;
      a {
        p {
          color: $collection-card-stripe-dark-a-p-color;
        }
      }
    }
    &.light {
      background-color: $collection-card-stripe-light-bgc;
      a {
        p {
          color: $collection-card-stripe-light-a-p-color;
        }
      }
    }
    a {
      p {
        padding: 0;
        margin: 0;
        letter-spacing: $collection-card-stripe-letter-spacing;
      }
    }
  }
}

.rating {
  padding: 0;
  color: $ecommerce-rating-color;
  list-style-type: none;
  li {
    display: inline-block;
  }
}

// Cart modal
.cart-modal {
  .table,
  th {
    text-align: center;
  }
  .table {
    .total {
      font-weight: 600;
      text-transform: uppercase;
    }
    .fa-times {
      color: $primary-color;
    }
  }
}

.card {
  &.card-ecommerce {
    .card-footer {
      background-color: transparent;
      .discount {
        color: $ecommerce-card-footer-discount-color;
      }
      a {
        color: $ecommerce-card-footer-a-color;
        transition: $ecommerce-card-footer-a-hover-transition;
        &:hover {
          color: $ecommerce-card-footer-a-hover-color;
          transition: $ecommerce-card-footer-a-hover-transition;
        }
        &.active {
          color: $ecommerce-card-footer-a-active-color;
        }
      }
    }
  }
}

// Product table
.product-table {
  .btn {
    &.btn-sm {
      padding: $ecommerce-product-table-btn-sm-py $ecommerce-product-table-btn-sm-px;
    }
  }
}
