$item-height: 50;
$icon-size: 24;

.side-nav {
    background-color: color('nijmegen-backgrounds', 'primary');
    padding-top: 40px;

    .search-form {

        form {
            margin: 0;
            padding: 0;
            position: relative;

            .form-control {
                background-color: transparent;
                border: 0;
                border-bottom: 2px solid color('nijmegen-grey', 'base');
                border-radius: 0;
                color: color('white', 'base');
                font-size: to-rem(16);
                margin: .2rem 0 1rem;
                padding: 1rem 40px .5rem 20px;
                width: 100%;

                &::placeholder {
                    color: color('white', 'base');
                }

                &:focus {
                    border-bottom: 2px solid color('nijmegen-primary', 'base');
                    box-shadow: none;

                    &::placeholder {
                        color: color('white', 'base');
                    }

                    + i  {
                        color: color('white', 'base');
                    }
                }
            }

            i {
                color: color('white', 'base');
                margin-right: 8px;
                margin-top: -12px;
                position: absolute;
                right: 0;
                top: 50%;

                &:hover {
                    cursor: default;
                }
            }
        }
    }

    .collapsible.collapsible-accordion {
        li {
            border-bottom: 1px solid color('nijmegen-grey', 'darken-2');
            padding: 0;

            &.active i.rotate-icon {
                color: color('white', 'base');
                transform: rotate(180deg);
            }

            a.active {
                background-color: color('nijmegen-backgrounds', 'sidebar-title-active');

            }

            a {
                font-size: to-rem(16);
                height: to-rem($item-height);
                line-height: to-rem($item-height);
                margin: 0;
                padding: 0 0 0 20px;

                &:hover {
                    background-color: color('nijmegen-backgrounds', 'sidebar-hover');

                    i.rotate-icon {
                        color: color('white', 'base');
                    }
                }

                i {
                    color: color('nijmegen-grey', 'darken-1');
                    margin-right: 5px;
                    vertical-align: middle;

                    &.rotate-icon {
                        position: absolute;
                        right: 0;
                        top: to-rem(($item-height / 2) - ($icon-size / 2));
                    }
                }
            }

            .collapsible-body {
                ul {
                    background-color: $body-color;

                    li a {
                        font-size: to-rem(16);
                        padding-left: to-rem(50);

                        &:hover {
                            background-color: $body-color;
                            color: color('nijmegen-backgrounds', 'dark-background-active');
                        }
                    }
                }
            }
        }
    }
}

#sidenav-overlay {
    background-color: rgba(0, 0, 0, .2);
}
