.switch-toggle {
    height: 1.75em;
    margin: 0 10px;
    position: relative;
    width: 3em;
}

.switch-toggle,
.switch-false,
.switch-true {
    float: right;
}


.check-switch {
    display: block;
    overflow: hidden;
    padding: .5em;
    position: relative;

    &:hover {
        box-shadow: 0 0 2px 1px $focus-color;
    }

    span.switch-toggle:before,
    span.switch-toggle:after {
        border: 1px solid #565656;
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    span.switch-toggle:after {
        background-color: #F1F1F1;
        border: 0;
        border-radius: 1.3125rem;
        box-shadow: 0 .0625rem .1875rem .0625rem rgba(0, 0, 0, .4);
        height: 1.3125rem;
        right: 1.5em;
        transition: right .3s ease, background .3s ease, box-shadow 1s ease;
        width: 1.3125rem;
    }

    span.switch-toggle:before {
        background-color: #818181;
        border: 0;
        border-radius: .9375rem;
        height: .9375rem;
        right: .25em;
        transition: background .3s ease;
        vertical-align: middle;
        width: 2.5rem;
    }

    input {
        height: 100%;
        left: 0;
        opacity: .0001;
        position: absolute;
        top: 0;
        width: 100%;

        &:not([role='button']) {
            pointer-events: none;
        }

        &:focus + span.switch-toggle:before {
            box-shadow: 0 0 2px 1px $focus-color;
        }

        &:checked + span.switch-toggle:after {
            background-color: #157c68;
            right: .25em;
        }

        &:checked + span.switch-toggle:before {
            background: #dccfe2;
        }
    }
}


@media screen and (-ms-high-contrast: active) {
    .check-switch span.switch-toggle:after {
        background-color: windowText;
    }
}
