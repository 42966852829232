.chip {
    background-color: color('white', 'base');
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    color: $body-color;
    font-size: to-rem(13);
    font-weight: $font-weight-semibold;

    // in MDB v4.3.2 we had a shadow, yet in v4.5.0 it's removed but design in Zeplin defines a shadow
    // thus restore it here
    padding-right: 16px;

    .close {
        color: color('nijmegen-primary', 'base');
        font-size: to-rem(16);
        font-weight: $font-weight-semibold;
        margin-right: -8px;

        &:focus,
        &:hover {
            color: color('nijmegen-primary', 'darken-2');
        }
    }
}
