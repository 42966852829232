// sass-lint:disable clean-import-paths
// Material design icons
$mdi-font-path: '../../node_modules/@mdi/font/fonts';

@import '~@mdi/font/scss/_variables';
@import '~@mdi/font/scss/_functions';

@font-face {
    font-display: block;
    font-family: '#{$mdi-font-name}';
    font-style: normal;
    font-weight: normal;
    src:
    url('#{$mdi-font-path}/#{$mdi-filename}-webfont.woff2') format('woff2'),
    url('#{$mdi-font-path}/#{$mdi-filename}-webfont.woff') format('woff');
}

@import '~@mdi/font/scss/_core';
@import '~@mdi/font/scss/_icons';
@import '~@mdi/font/scss/_extras';
@import '~@mdi/font/scss/_animated';

i.mdi,
span.mdi {
    display: inline-block;
    font-size: 1.5rem;
    line-height: 1;
}
