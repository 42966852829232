.pagination {
    .page-item {
        .page-link {
            color: color('nijmegen-primary', 'base');
            font-size: 1.125rem;
            height: 36px;
            line-height: 36px;
            margin: 0 1px;
            padding: 0;
            text-align: center;
            width: 36px;

            &:hover      {
                background-color: color('nijmegen-grey', 'lighten-1');
                box-shadow: none;
                color: color('nijmegen-primary', 'darken-1');
            }

            &:focus {
                @include tab-focus;
                box-shadow: none;
            }

            > [aria-hidden='true'] {
                line-height: 42px;
            }
        }

        &.disabled .page-link,
        &:disabled {
            color: color('nijmegen-forms', 'disabled');
        }

        &.active .page-link {
            background-color: color('nijmegen-backgrounds', 'pagination-active');
            box-shadow: none;
            color: color('white', 'base');

            &:hover,
            &:focus {
                background-color: color('nijmegen-backgrounds', 'pagination-active');
                box-shadow: none;
            }
        }
    }
}
