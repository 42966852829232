.autocomplete {
    &__result-list {
        background: color('white', 'base');
        box-shadow: 3px 3px 6px rgba(0, 0, 0, .25), 0 0 15px rgba(0, 0, 0, .15);
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__result-item {
        font-size: to-rem(18);
        word-break: break-word;

        &:not(:last-of-type) {
            border-bottom: 1px solid color('nijmegen-grey', 'base');
        }
    }

    &__input {
        padding-right: to-rem(30);
        &::-ms-clear {
            display: none;
        }
    }

    &__result-link {
        color: $body-color;
        display: block;
        padding: .5rem 1rem .5rem 2rem;
        position: relative;
        transition: none;

        em {
            font-style: normal;
            font-weight: bold;
        }

        &:focus,
        &.active {
            background-color: color('nijmegen-grey', 'darken-2');
            color: color('white', 'base');
            cursor: pointer;
            transition: none;
        }

        &::before {
            @include mdi('arrow-right');
        }

        &.search::before {
            @include mdi('magnify');
        }

        &::before, &.search::before{
            display: block;
            font-size: to-rem(16);
            left: .5rem;
            line-height: to-rem(18*1.5);
            position: absolute;
            top: .5rem;
        }
    }

    &__section-header {
        display: block;
        font-size: to-rem(22);
        padding: .5rem .5rem 0;
        position: relative;
    }

    &__search-button, &__clear-button {
        background-color: transparent;
        border: 0;
        box-sizing: content-box;
        display: block;
        float: right;
        height: 30px;
        padding-top: 3px;
        position: relative;
        top: -35px;
        width: 30px;

        &:hover,
        &:focus {
            @include tab-focus-minimal-inverted;
        }
    }

    &__button--hide {
        display: none;
    }

    &__search-button {
        &:hover {
            cursor: default;
        }
    }
}
