.search-results {
    &__title {
        color: $body-color;
        font-size: to-rem(20);
        font-weight: bold;
    }

    &__item {
        border-bottom: 1px solid color('nijmegen-grey', 'base');
        margin-bottom: to-rem(20);
        padding-bottom: to-rem(20);
    }

    &__item-title {
        color: color('nijmegen-brand', 'base');
        font-size: to-rem(28);

        em {
            font-style: normal;
        }
    }

    &__item-body em {
        background: color('nijmegen-grey', 'lighten-1');
        color: color('nijmegen-primary', 'darken-1');
        font-style: normal;
        padding: 0 to-rem(2);
    }

    &__noresults,
    &__didyoumean {
        display: none;
    }

    &__pagination {
        display: flex;
        justify-content: center;
    }
}

#searchresults {
    display: none;
}
