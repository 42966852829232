.facets {
    &.facets--hide {
        display: none;
    }

    &__title{
        background: none;
        border: 0;
        border-bottom: 1px solid color('nijmegen-grey', 'base');
        color: $body-color;
        cursor: pointer;
        display: block;
        font-size: to-rem(18);
        line-height: 1.2;
        margin-bottom: to-rem(8);
        padding: to-rem(20) 0;
        position: relative;
        text-align: left;
        width: 100%;

        &::before{
            @include mdi('filter');
            font-size: to-rem(18);
            padding-right: to-rem(10);
        }

        &::after{
            color: color('nijmegen-brand', 'base');
            content: '\25BE';
            position: absolute;
            right: to-rem(10);
            top: to-rem(20);
        }

        &:focus {
            @include tab-focus-minimal;
        }

        &.expanded::after {
            content: '\25B4'
        }

        &:not(.allow-collapse-on-desktop){
            @media #{$large-and-up} {
                display: none;
            }
        }
    }

    &__facets {
        @media #{$medium-and-down} {
            border-bottom: 1px solid color('nijmegen-grey', 'base');
            margin-bottom: to-rem(20);

            &:not(.expanded){
                display: none;
            }
        }
        &.allow-collapse-on-desktop:not(.expanded){
            display: none;
        }
    }

    &__group-title {
        color: color('nijmegen-brand', 'base');
        font-size: to-rem(28);

        @media #{$medium-and-down} {
            font-size: to-rem(20);
        }

        &::first-letter {
            text-transform: capitalize;
        }
    }

    &__item {
        line-height: 1.5rem;
        position: relative;

        &::first-letter {
            text-transform: capitalize;
        }

        &.active::before{
            @include mdi('menu-right');
            color: color('nijmegen-primary', 'base');
            left: -24px;
            position: absolute;
            top: 0;
        }
    }
}
