.time-picker {
    button {
        background: none;
        border: 0;
        float: right;
        position: relative;
        top: -42px;
    }

    input {
        &:focus:not([readonly]) {
            ~ label {
                color: color('nijmegen-primary', 'base');
            }
        }

        &.invalid,
        &:focus.invalid {
            & ~ label {
                &:before,
                &:after {
                    color: color('nijmegen-forms', 'error');
                }
            }
        }
    }
}

.picker {
    .picker__date-display {
        background-color: color('nijmegen-brand', 'base');
        padding-bottom: 0;
    }

    .clockpicker-display {
        color: color('white', 'base');
        font-family: 'Oranda', sans-serif;
        font-size: to-rem(38);
        height: auto;
        padding-bottom: 10px;

        &-column {
            .clockpicker-span-hours.text-primary,
            .clockpicker-span-minutes.text-primary {
                // this !important is required because the boostrap.min.css
                // has all the color set with a !important
                color: $white !important;
            }
        }
    }

    // .picker__date-display .clockpicker-display

    .clockpicker-display-am-pm,
    .clockpicker-am-pm-block {
        display: none;
    }

    .clockpicker-plate {
        background-color: transparent;
        border: 2px solid color('nijmegen-grey', 'base');
    }

    .clockpicker-dial {
        font-size: to-rem(14);
    }

    .picker__footer {
        padding: 10px;

        .clockpicker-button {
            color: color('nijmegen-primary', 'base');
            cursor: pointer;
            font-size: to-rem(16);
            font-weight: $font-weight-normal;
            letter-spacing: 0;
            margin-left: auto;
            margin-right: initial;
            text-transform: capitalize;
            width: auto;
        }
    }
}

.clockpicker-svg {
    line {
        stroke: rgba(color('nijmegen-forms', 'picker-focus'), .9);
    }
    .clockpicker-canvas-bg {
        fill: rgba(color('nijmegen-forms', 'picker-focus'), .9);
    }

    .clockpicker-canvas-fg {
        fill: transparent;
    }

    .clockpicker-canvas-bearing {
        fill: color('nijmegen-primary', 'lighten-1');
        r: 4;
    }
}
