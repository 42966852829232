.btn-floating-primary {
    background-color: color('nijmegen-primary', 'base');
    border: 0;
    color: color('white', 'base');

    &:hover {
        background-color: color('nijmegen-primary', 'lighten-1');
    }

    &:focus {
        &:after {
            border: 1px solid $focus-color;
        }
    }

    &:active{
        background-color: color('nijmegen-primary', 'darken-3');
    }
}


.btn-floating.btn-large {
    height: to-rem(56);
    width: to-rem(56);

    i {
        font-size: to-rem(28);
        line-height: (56 / 28);
    }
}

.btn-floating {
    &:focus {
        outline: none;
        overflow: initial;
        position: relative;

        &:after {
            border-radius: 100%;
            bottom: to-rem(-7);
            content: '';
            left: to-rem(-7);
            position: absolute;
            right: to-rem(-7);
            top: to-rem(-7);
        }
    }
}

// This comes directly from mdb
.btn-floating.btn-small .mdi {
    font-size: .93rem;
    line-height: 37px;
    margin-top: 5px;
}
