$border-bottom-size: 5; // Size is in pt
$text-height: to-rem(48 - $border-bottom-size);

.classic-tabs-no-line,
.classic-tabs {
    display: flex;
    overflow-x: auto;
    position: relative;
    white-space: nowrap;

    .nav-item, .pills__item {
        flex: inherit;
        height: to-rem(48);
        position: relative;
        z-index: 2;

        &:first-child {
            margin-left: 0;
        }

        &:not(:last-child):after {
            border-right: to-rem(1) solid color('nijmegen-grey', 'base');
            bottom: 12px;
            content: '';
            position: absolute;
            right: 0;
            top: 12px;
        }

        button,
        a {
            -moz-appearance: none;
            -webkit-appearance: none;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            color: color('nijmegen-brand', 'base');
            cursor: pointer;
            display: inline-block;
            font-size: to-rem(18);
            height: to-rem(48);
            line-height: $text-height;
            outline: none;
            padding-bottom: to-rem($border-bottom-size) solid color('nijmegen-grey', 'base');
            padding-top: 0;
            text-align: left;
            text-decoration: none;
            text-transform: none;

            &.active {
                border-bottom: to-rem($border-bottom-size) solid $body-color;
                color: $body-color;
            }

            &:hover {
                border-bottom: to-rem($border-bottom-size) solid color('nijmegen-brand', 'darken-2');
                color: color('nijmegen-brand', 'darken-2');
            }

            &:focus {
                background: rgba($focus-color, .2);
                border-radius: 2px;
            }
        }
    }
}

.classic-tabs {
    background: linear-gradient(color('nijmegen-grey', 'base'), color('nijmegen-grey', 'base'));
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 5px;
    border-left: to-rem(1) solid color('nijmegen-grey', 'base');
    border-right: to-rem(1) solid color('nijmegen-grey', 'base');
    border-top: to-rem(1) solid color('nijmegen-grey', 'base');

    li {
        background: linear-gradient(color('nijmegen-grey', 'base'), color('nijmegen-grey', 'base'));
        background-position: 0 100%;
        background-repeat: no-repeat;
        background-size: 100% 5px;
    }
}

.tab-content {
    box-shadow: none;
}
