.rgba-grey-light {
    background-color: rgba($body-color, .3);
}

.rgba-grey-strong {
    background-color: rgba($body-color, .7);
}

.rgba-grey-slight {
    background-color: rgba($body-color, .1);
}

.mask .white-text {
    font-weight: $font-weight-semibold;
}
