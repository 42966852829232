.pills {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;

    &__item {
        padding: .6rem;
    }

    &__link,
    &__button
    {
        background: none;
        border-radius: 2px;
        color: color('nijmegen-primary', 'base');
        display: inline-block;
        padding: .5rem 1rem;

        &:hover,
        &:focus
        {
            background: color('nijmegen-grey', 'base');
            color: color('nijmegen-primary', 'darken-1');
            outline: none;
        }

        &:focus {
            outline: 1px;
        }

        &[aria-current],
        &[aria-selected='true']
        {
            background: color('nijmegen-primary', 'base');
            color: color('white', 'base');

            &:hover,
            &:focus {
                @include tab-focus-minimal;
                outline-offset: 2px;
                outline-width: 2px;
            }
        }
    }

    &__button {
        border: 0;
    }

    &.pills--secondary &__link,
    &.pills--secondary &__button
    {
        color: color('nijmegen-brand', 'base');

        &:hover,
        &:focus
        {
            color: color('nijmegen-brand', 'base');
        }

        &[aria-current],
        &[aria-selected='true']
        {
            background: color('nijmegen-brand', 'base');
            color: color('white', 'base');
        }
    }
}
