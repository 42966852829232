// Treeview
.treeview {
  &.w-20 {
    width: 20rem;
  }
  .rotate {
    margin-top: .2rem;
    font-size: .8rem;
    vertical-align: text-top;
    cursor: pointer;
    user-select: none;
    transition: all .1s linear;
    &.down {
      transform: rotate(90deg);
    }
  }
  .nested {
    display: none;
  }
  .active {
    display: block;
  }
  ul {
    list-style-type: none;
  }
  .ic-w {
    width: 1.3rem;
  }
}

.treeview-animated {
  &.w-20 {
    width: 20rem;
  }
  ul {
    position: relative;
    padding-left: 1em;
    list-style: none;
  }

  .treeview-animated-list {
    li {
      padding: .2em 0 0 .2em;
    }

    .treeview-animated-items {

      .nested {
        &::before {
          position: absolute;
          left: 5px;
          display: block;
          width: 5px;
          height: 100%;
          content: "";
          background-color: #808080;
        }
      }

      .closed {
        display: block;
        padding: .2em .2em .2em .4em;
        margin-right: 0;
        border-top-left-radius: .3em;
        border-bottom-left-radius: .3em;

        &:hover {
          background-color: rgb(140, 185, 255);
        }

        .fa-angle-right {
          font-size: .8rem;
          transition: all .1s linear;

          &.down {
            position: relative;
            color: #f8f9fa;
            transform: rotate(90deg);
          }
        }
      }

      .open {
        background-color: rgb(50, 160, 255);
        transition: all .1s linear;

        &:hover {
          color: #f8f9fa;
          background-color: rgb(50, 160, 255);
        }

        span {
          color: #f8f9fa;
        }
      }
    }

    .treeview-animated-element {
      padding: .2em .2em .2em .6em;
      cursor: pointer;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      transition: all .1s linear;

      &:hover {
        background-color: rgb(140, 185, 255);
      }

      &.opened {
        color: #f8f9fa;
        background-color: rgb(50, 160, 255);

        &:hover {
          color: #f8f9fa;
          background-color: rgb(50, 160, 255);
        }
      }
    }
  }
}

.treeview-colorful {
  font-size: 16px;
  font-weight: 400;
  background: rgba(224, 127, 178, .2);

  &.w-20 {
    width: 20rem;
  }

  hr {
    border-color: #a2127a;
  }

  h6 {
    font-size: 1.4em;
    font-weight: 500;
    color: #a2127a;
  }

  ul {
    position: relative;
    padding-left: 0;
    list-style: none;
  }

  .treeview-colorful-list {

    ul {
      padding-left: 1em;
      margin-top: .1em;
      background: rgba(224, 127, 178, .2);
    }
  }

  .treeview-colorful-element {
    padding: .2em .2em .2em 1em;
    cursor: pointer;
    border: 2px solid transparent;
    border-right: 0 solid transparent;
    transition: all .1s linear;

    &:hover {
      background-color: #e07fb2;
    }

    &.opened {
      color: #ffac47;
      background-color: #a2127a;
      border: 2px solid #ffac47;
      border-right: 0 solid transparent;

      &:hover {
        color: #ffac47;
        background-color: #a2127a;
      }
    }

  }
  .treeview-colorful-items-header {
    display: block;
    padding: .4em;
    margin-right: 0;
    border-bottom: 2px solid transparent;
    transition: all .1s linear;

    &:hover {
      background-color: #e07fb2;
    }

    &.open {
      background-color: #a2127a;
      border-bottom: 2px solid #ffac47;
      transition: all .1s linear;

      span {
        color: #ffac47;
      }

      &:hover {
        color: #ffac47;
        background-color: #a2127a;
      }

      div:hover {
        background-color: #a2127a;
      }
    }

    .fa-angle-right {
      font-size: .8rem;
      transition: all .2s linear;
    }

    .fas {
      position: relative;
      color: #ffac47;
      transition: all .2s linear;
      transform: rotate(90deg);
    }

    .fa-minus-circle {
      position: relative;
      color: #ffac47;
      transition: all .2s linear;
      transform: rotate(180deg);
    }
  }
}
