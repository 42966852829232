footer.page-footer {
    background-color: color('nijmegen-backgrounds', 'footer-primary');
    margin-top: 0;
    padding-top: 30px;

    .title {
        color: color('white', 'base');
        font-family: 'Source Sans Pro', sans-serif;
        font-size: to-rem(20);
        font-weight: normal;
        line-height: to-rem(26);
        margin-bottom: .5rem;
        text-transform: none;
    }

    .footer-copyright {
        background-color: color('nijmegen-backgrounds', 'default');
        color: color('white', 'base');
        font-size: to-rem(16);
        height: to-rem(78);
        line-height: to-rem(78);

        img {
            margin-right: 10px;
        }
    }
}

.footer-content {
    .link-list-wrapper {
        display: flex;

        > .link-list {
            flex: 1;
        }
    }

    table tbody tr td,
    .link-list li a {
        font-size: to-rem(14);
        letter-spacing: to-rem(.1);
        padding-bottom: to-rem(4);
        padding-top: to-rem(4);
    }

    .contact-list li a {
        font-size: to-rem(16);
    }

    .link-list li {
        line-height: to-rem(20);
        padding: to-rem(4) 0 to-rem(4) to-rem(8);

        a {
            line-height: to-rem(35);

            &:hover {
                text-decoration: underline;
            }
        }

        span {
            font-size: to-rem(19);
            padding: to-rem(10) to-rem(8);
            vertical-align: middle;
        }
    }

    table {
        margin-bottom: 1rem;
        width: 100%;

        thead th {
            line-height: 0;

            .badge {
                font-size: to-rem(12);
                padding: .25rem;
            }
        }

        tbody tr td {
            &:first-child {
                padding-left: to-rem(8);
                padding-right: to-rem(4);
            }

            &:last-child {
                padding-left: to-rem(4);
            }
        }
    }
}
