.mdb-lightbox figure {
    position: relative;

    a {
        &:hover {
            cursor: zoom-in;
            display: block;

            &:before {
                background-color: rgba($focus-color, .2);
                bottom: .5rem;
                content: '';
                left: .5rem;
                position: absolute;
                right: .5rem;
                top: .5rem;
            }
        }

        &:focus {
            outline: none;

            &:after {
                border: 3px solid $focus-color;
                bottom: 0;
                content: '';
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        img {
            &:hover {
                opacity: 1;
            }
        }
    }
}
