// Mega menu

// stylelint-disable selector-max-class
// stylelint-disable selector-max-combinators
// stylelint-disable selector-max-compound-selectors
// stylelint-disable selector-max-type
.navbar {
  .mega-dropdown {
    position: static;
    .dropdown-menu {
      &.mega-menu {
        width: 100%;
        border: none;
        border-radius: 0;
        .sub-menu {
          .news-title {
            font-size: 1.1rem;
            transition: .2s;
            &.smaller {
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.4;
            }
          }
          .sub-title {
            border-bottom: 1px solid #e0e0e0;
          }
          ul {
            li {
              a {
                width: 100%;
                transition: .3s;
                &:hover {
                  background-color: rgba(0, 0, 0, .2);
                  transition: .3s;
                }
              }
            }
          }
        }
        &.v-1 {
          .sub-menu {
            .news-single {
              border-bottom: 1px solid #e0e0e0;
            }
            .news-title {
              color: #4f4f4f;
              &:hover {
                color: #2196f3;
              }
            }
            .m-sm {
              margin-bottom: -6px;
              font-size: .9rem;
              color: #2196f3;
              &:hover {
                color: #2196f3;
              }
            }
          }
        }
        &.v-2 {
          .sub-menu {
            .news-title {
              color: #fff;
            }
            ul {
              li {
                a {
                  color: #fff;
                  &:hover {
                    color: #fff;
                  }
                }
              }
            }
            .sub-title {
              padding-bottom: 1rem;
              margin-bottom: 1rem;
            }
          }
        }
        &.v-3 {
          .sub-menu {
            ul {
              li {
                a {
                  color: #fff;
                  &:hover {
                    color: #fff;
                  }
                }
              }
            }
            .news-title {
              color: #fff;
              &:hover {
                color: #e0e0e0;
              }
            }
          }
        }
        @media (max-width: 1024px) {
          max-height: 300px;
          overflow-x: hidden;
          overflow-y: auto;
        }
      }
    }
  }
  .dropdown {
    &.multi-level-dropdown {
      .dropdown-menu {
        .dropdown-submenu {
          position: relative;
          .dropdown-menu {
            top: 0;
            left: 100%;
            &.r-100 {
              right: 100%;
            }
            &.l-auto {
              left: auto;
            }
          }
        }
        .dropdown-item {
          width: 100%;
          transition: .3s;
          &:hover {
            background-color: rgba(0, 0, 0, .2);
            border-radius: 0;
            box-shadow: none;
            transition: .3s;
          }
        }
      }
    }
  }
}
