.listing-item {
    color: $body-color;
    display: block;
    padding: 10px;

    .title {
        color: color('nijmegen-brand', 'base');
        font-family: 'Oranda', sans-serif;
        font-size: to-rem(36);
        line-height: 1.5;
        margin-bottom: 1rem;
    }

    &:focus {
        @include tab-focus;

        .title {
            color: color('nijmegen-brand', 'darken-2');
            outline: none;
        }
    }

    &:hover {
        .title {
            color: color('nijmegen-brand', 'darken-2');
        }

        p {
            color: $body-color;
        }
    }
}
