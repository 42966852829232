$icon-spacing: 70px;
$icon-size: 24px;

#toast-container {
    > .toast {
        background-image: none;
        box-shadow: none;
        color: $body-color;
        margin: 15px 0;
        opacity: 1;
        padding-left: $icon-spacing;
        position: relative;

        .toast-message {
            line-height: 30px;
        }

        .toast-close-button {
            display: block;
        }

        &:before {
            color: color('black', 'base');
            font-size: $icon-size;
            left: 0;
            position: absolute;
            text-align: center;
            top: calc(50% - #{$icon-size / 2});
            width: $icon-spacing;
        }
    }

    .toast-close-button {
        color: $body-color;
        opacity: 1;

        &:hover {
            text-shadow: 0 0 5px rgba(color('black', 'base'), .5);
        }

        &:focus {
            @include tab-focus;
        }
    }

    .toast-success {
        background-color: color('nijmegen-backgrounds', 'success');

        &:before {
            @include mdi('check');
        }
    }

    .toast-info {
        background-color: color('nijmegen-backgrounds', 'info');

        &:before {
            @include mdi('information');
        }
    }

    .toast-warning {
        background-color: color('nijmegen-backgrounds', 'warning');

        &:before {
            @include mdi('alert');
        }
    }

    .toast-error {
        background-color: color('nijmegen-backgrounds', 'danger');
        color: color('white', 'base');

        .toast-close-button {
            color: color('white', 'base');

            &:hover {
                text-shadow: 0 0 5px rgba(color('white', 'base'), .5);
            }
        }

        &:before {
            @include mdi('information');
            color: color('white', 'base');
        }
    }
}
