table.responsive-table {
    border: 0;
    width: 100%;

    caption {
        caption-side: top;
        font-size: to-rem(18);

        .summary {
            font-size: to-rem(14);
        }
    }

    tbody tr td:before,
    thead tr th {
        border-bottom: 2px solid color('nijmegen-grey', 'base');
        color: color('nijmegen-grey', 'darken-2');
        font-size: to-rem(14);
        font-weight: $font-weight-normal;
        letter-spacing: .1px;
        padding-bottom: 6px;
        padding-top: 6px;
    }

    tbody tr {
        padding-bottom: 6px;
        padding-top: 6px;

        td, th {
            border-bottom: 2px solid color('nijmegen-grey', 'base');
            color: $body-color;
            font-size: to-rem(16);
            padding-bottom: 5px;
            padding-top: 5px;
        }
    }

    @media only screen
    and (max-width: 760px), (min-device-width: $medium-screen-portrait)
    and (max-device-width: 1024px) {

        &,
        & thead,
        & tbody,
        & th,
        & td,
        & tr {
            display: block;
        }

        caption {
            display: block;
        }

        & thead tr {
            left: -9999px;
            position: absolute;
            top: -9999px;
        }

        tbody {
            tr {
                border-top: 1px solid #ccc;
                padding-bottom: 6px;
                padding-top: 6px;

                &:last-child {
                    border-bottom: 1px solid color('nijmegen-grey', 'base');
                }


                td {
                    border: 0;
                    margin-left: 50%;
                    padding-bottom: 5px;
                    padding-top: 5px;
                    position: relative;
                    width: 50%;

                    &:before {
                        border-bottom: 0;
                        content: attr(data-title);
                        display: inline-block;
                        float: left;
                        margin-left: -100%;
                        padding-bottom: 0;
                        padding-top: 3px;
                        white-space: normal;
                        width: 100%;
                    }

                    &:after {
                        clear: both;
                        content: '';
                        display: block;
                        height: 0;
                        visibility: hidden;
                    }
                }
            }
        }
    }

    &.complex-table {
        thead tr {
            th:first-child {
                border-right: 2px solid color('nijmegen-grey', 'base');
            }
            th:nth-child(2) {
                padding-left: 5px;
            }
        }
        tbody tr {
            th {
                border-right: 2px solid color('nijmegen-grey', 'base');
                font-weight: bold;
            }
            td:first-of-type{
                padding-left: 5px;
            }
        }

        @media only screen
        and (max-width: 760px), (min-device-width: $medium-screen-portrait)
        and (max-device-width: 1024px) {
            tr {
                border-top: 0;
            }

            thead tr {
                th:first-child {
                    border-right: 0;
                }
                th:nth-child(2) {
                    padding-left: 1px;
                }
            }
            tbody tr {
                th {
                    border-right: 0;
                    font-weight: bold;
                }
                td:first-of-type{
                    padding-left: 1px;
                }
            }
        }
    }
}
