.list-group {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);

    ul:not([list-group]) {
        margin-left: 0;
        padding-left: 0;
        position: relative;

        li:before {
            content: '';
        }
    }

    .list-group-item,
    .list-group-title,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        background-color: transparent;
        border-bottom: 1px solid color('nijmegen-grey', 'base');
        border-left: 0;
        border-radius: 0;
        border-right: 0;
        border-top: 0;
        margin-bottom: 0;
    }

    .list-group-title,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: color('nijmegen-grey', 'darken-2');
        font-family: 'Oranda', sans-serif;
        font-size: to-rem(24);
        line-height: 1.5;
        padding: .75rem 1.25rem;
    }

    a.list-group-item {
        text-decoration: underline;

        &.active {
            background-color: color('nijmegen-grey', 'lighten-1');
            color: color('nijmegen-primary', 'darken-2');
        }

        &:hover,
        &:focus {
            background-color: color('nijmegen-grey', 'lighten-1');
            color: color('nijmegen-primary', 'darken-2');
        }

        &:active,
        &:focus {
            @include tab-focus;
        }
    }

    li:last-child {
        border-bottom: 0;
    }
}
