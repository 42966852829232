.popover {
    border: 0;
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    padding: 0;
    position: relative;

    .popover-close {
        background: none;
        border: 0;
        font-size: to-rem(28);
        line-height: 1.2;
        padding: 15px 14px;
        position: absolute;
        right: 0;
        top: 0;
        &:after{
            @include mdi('close');
            color: color('nijmegen-primary', 'base');
            font-size: to-rem(20);
        }
    }

    .popover-header {
        background-color: color('nijmegen-grey', 'lighten-1');
        border-bottom: 0;
        color: $body-color;
        font-size: to-rem(28);
        padding: 15px 40px 15px 14px;
    }

    .popover-body {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: to-rem(16);
        line-height: 26px;
        padding: 14px 20px;
    }

    &.bs-popover-top .arrow::before {
        border-top-color: rgba(0, 0, 0, .1);
    }

    &.bs-popover-left .arrow::before {
        border-left-color: rgba(0, 0, 0, .1);
    }

    &.bs-popover-right .arrow::before {
        border-right-color: rgba(0, 0, 0, .1);
    }

    &.bs-popover-bottom .arrow::before {
        border-bottom-color: rgba(0, 0, 0, .1);
    }

    &.bs-popover-bottom .arrow::after {
        border-bottom-color: color('nijmegen-grey', 'lighten-1');
    }
}
