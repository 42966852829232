.accordion {
    .card {
        .card-header {
            padding: 0;

            h1, h2, h3, h4, h5, h6 {
                color: color('nijmegen-primary', 'base');
                font-family: 'Oranda', sans-serif;
                font-size: to-rem(26);

                @media only screen and (min-width: #{$medium-screen-portrait}) {
                    font-size: to-rem(28);
                }
            }

            &:focus,
            &:hover {
                h1, h2, h3, h4, h5, h6,
                span {
                    color: color('nijmegen-primary', 'darken-2');
                }
            }
        }

        .card-block {
            padding: to-rem(10);
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    &__button {
        background: none;
        border: 0;
        color: inherit;;
        display: block;
        padding: to-rem(26) to-rem(10);
        text-align: left;
        width: 100%;

        &:not(.collapsed) {
            color: color('nijmegen-primary', 'darken-3');
        }

        &:focus {
            @include tab-focus;
        }

        &::after {
            @include mdi('chevron-up');
            float: right;
            font-size: to-rem(36);
            line-height: to-rem(30);
        }

        &.collapsed::after{
            @include mdi('chevron-down');
            float: right;
            font-size: to-rem(36);
            line-height: to-rem(30);
        }
    }
}
