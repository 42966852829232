.modal-footer,
.modal-header {
    border: 0;
}

.modal-header {
    .close {
        border-radius: .125rem;
        color: color('nijmegen-primary', 'base');
        margin: -.25rem -.75rem -.75rem;
        opacity: 1;
        padding: .75rem;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
            background: color('nijmegen-grey', 'lighten-1');
            color: color('nijmegen-primary', 'darken-2');
            opacity: 1;
        }

        &:focus {
            @include tab-focus;
        }
    }
}

.modal-title {
    @include web-font-rendering-support;

    color: $body-color;
    font-family: 'Oranda', sans-serif;
    font-size: to-rem(26);
    font-weight: $font-weight-normal;

    @media only screen and (min-width: #{$medium-screen-portrait}) {
        font-size: to-rem(28);
    }
}

.modal-body {
    padding-bottom: 0;
    padding-top: 0;
}
