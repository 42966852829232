.date-picker {
    > button {
        background: none;
        border: 0;
        float: right;
        position: relative;
        top: -42px;
    }
}

.picker {
    .picker__header .picker__date-display {
        background-color: color('nijmegen-brand', 'base');
        padding-bottom: 15px;

        .picker__year-display {
            color: color('white', 'base');
        }
    }

    .picker__header .picker__nav--next,
    .picker__header .picker__nav--prev {
        width: auto;
        &:hover {
            background: none;
        }

        &:before {
            border: 0;
            color: color('nijmegen-primary', 'base');
            display: block;
            font-size: to-rem(18);
            margin: initial;
        }
    }

    .picker__header .picker__nav--prev {
        left: -.5em;
        text-align: left;

        &:before {
            @include mdi('arrow-left');
            font-size: to-rem(18);
        }
    }

    .picker__header .picker__nav--next {
        right: -.5em;
        text-align: right;

        &:before {
            @include mdi('arrow-right');
            font-size: to-rem(18);
        }
    }

    .picker__table {
        .picker__weekday {
            color: color('black', 'base');
            font-size: to-rem(14);
            font-weight: $font-weight-semibold;
            text-transform: capitalize;
        }

        .picker__day--infocus {
            color: color('nijmegen-grey', 'darken-2');
            font-size: to-rem(14);
            height: to-rem(27);
            line-height: to-rem(27);
            margin: 0 auto;
            padding: 0;
            width: to-rem(27);

            &:hover {
                color: color('nijmegen-forms', 'body-color');
            }

            &.picker__day--selected[aria-selected='true'] {
                background-color: color('nijmegen-forms', 'picker-focus');
                box-shadow: none;
                color: color('nijmegen-forms', 'body-color');
                font-weight: $font-weight-semibold;
                transform: initial;
            }
        }

        .picker__day.picker__day--today {
            color: color('nijmegen-primary', 'base');
        }
    }

    .picker__footer {
        .picker__button--close,
        .picker__button--today {
            color: color('nijmegen-primary', 'base');
            font-size: to-rem(16);
            font-weight: $font-weight-normal;
            text-transform: capitalize;

            &:before {
                border-color: transparent;
                content: initial;
            }

            &:focus,
            &:hover {
                background-color: transparent;
                border: 0;
            }
        }

        .picker__button--clear {
            display: none;
        }
    }
}
