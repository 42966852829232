$form-control-error-icon-size: 17;
$select-caret-icon-size: 24;
$form-label-top-offset: -1.5rem;
$form-drop-down-bottom-border: 1px solid rgba(0, 0, 0, .125);

.md-form {
    label {
        color: color('nijmegen-forms', 'unfocussed-gray');
        font-size: to-rem(16);
        width: 100%;

        &.active {
            font-weight: $font-weight-semibold;
            top: 1rem;
            transform: translateY(-160%);
        }
    }

    input[type='text']:not(.browser-default),
    input[type='password']:not(.browser-default),
    input[type='email']:not(.browser-default),
    input[type='url']:not(.browser-default),
    input[type='time']:not(.browser-default),
    input[type='date']:not(.browser-default),
    input[type='datetime-local']:not(.browser-default),
    input[type='tel']:not(.browser-default),
    input[type='number']:not(.browser-default),
    input[type='search-md']:not(.browser-default),
    input[type='search']:not(.browser-default),
    textarea.md-textarea:not(.browser-default),
    .aria-select button {
        box-sizing: border-box;

        &:not([disabled]){
            border-bottom-color: color('nijmegen-forms', 'unfocussed-gray');
        }

        &[disabled]{
            border-bottom-style: dotted;
        }

        &:focus:not([readonly]) {
            border-bottom-color: color('nijmegen-forms', 'focus');
            box-shadow: 0 1px 0 0 color('nijmegen-forms', 'focus');

            + label {
                color: color('nijmegen-primary', 'base');
            }
        }

        &.is-invalid,
        &:focus.is-invalid {
            background-image: none;
            border-bottom-color: color('nijmegen-forms', 'error');
            box-shadow: 0 1px 0 0 color('nijmegen-forms', 'error');

            & + label {
                &:before,
                &:after {
                    color: color('nijmegen-forms', 'error');
                }
            }
        }

        &.is-valid,
        &:focus.is-valid {
            background-image: none;
            border-bottom-color: color('nijmegen-forms', 'success');
            box-shadow: 0 1px 0 0 color('nijmegen-forms', 'success');
        }
    }

    .form-control {
        color: $body-color;
        font-size: to-rem(16);
        font-weight: $font-weight-semibold;
        padding: .55rem 0 .3rem;

        &::placeholder{
            color: color('nijmegen-forms', 'placeholder');
            font-weight: $font-weight-normal;
        }

        &:disabled,
        &:read-only {
            border-bottom: 1px dotted rgba(color('nijmegen-forms', 'disabled-border'), .26);
            font-weight: $font-weight-normal;
        }

        &:focus {
            color: $body-color;
        }

        &.is-valid + label:after,
        &.is-invalid + label:after {
            font-size: to-rem(14);
        }

        &.is-valid, &.is-invalid {
            & + label {
                &:before,
                &:after {
                    line-height: 1.2rem;
                    top: 4rem;
                }
            }
        }

        &.is-invalid {
            & + .invalid-feedback {
                &:before {
                    @include mdi('alert');
                    display: block;
                    font-size: to-rem($form-control-error-icon-size);
                    position: absolute;
                    right: 0;
                    transition: .2s opacity ease-out, .2s color ease-out;
                }
            }
        }
    }

    textarea.md-textarea {
        font-weight: $font-weight-normal;
        min-height: to-rem(92);
        padding: .6rem 0;
    }

    textarea.md-textarea + label {
        top: 1rem;
    }

    .valid-feedback {
        color: darken(color('nijmegen-forms', 'success'), 10%);
    }

    .invalid-feedback {
        color: color('nijmegen-forms', 'error');
    }
}

.mdb-select {
    .caret {
        position: relative;
        visibility: hidden;

        &:before {
            @include mdi('chevron-down');
            color: color('nijmegen-primary', 'base');
            font-size: to-rem($select-caret-icon-size);
            position: absolute;
            right: 15px;
            visibility: visible;
        }
    }

    & + label {
        font-weight: $font-weight-semibold;
        top: $form-label-top-offset;
    }

    &:hover {
        &:before {
            color: color('nijmegen-primary', 'darken-2');
        }
    }
}

.dropdown-content {
    li {
        border-bottom: $form-drop-down-bottom-border;

        span {
            color: $body-color;
            font-size: to-rem(16);
            padding: 1rem;
        }

        &.active {
            background-color: transparent;

            span {
                color: color('nijmegen-primary', 'base');
            }
        }

        &.selected {
            background-color: transparent;
            color: color('nijmegen-primary', 'base');
        }

        &:hover:not(.disabled) {
            background-color: color('nijmegen-grey', 'darken-2');
            span {
                color: color('white', 'base');
            }
        }

        &:last-child {
            border-bottom: 0;
        }

        &:first-child {
            position: relative;
        }

        &:first-child:after {
            @include mdi('chevron-up');
            color: color('nijmegen-primary', 'base');
            font-size: to-rem($select-caret-icon-size);
            margin-top: to-rem(-$select-caret-icon-size / 2);
            position: absolute;
            right: 15px;
            top: 50%;
            visibility: visible;
        }
    }
}

.form-control:focus {
    border-color: color('nijmegen-forms', 'focus');
    box-shadow: none;
}
