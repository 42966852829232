// Pricing section
.pricing-card {
  text-align: center;
  ul {
    padding: 0;
    list-style-type: none;
  }
  .header {
    box-shadow: $z-depth-1-half;
  }
  .option {
    padding: $pricing-card-option-padding;
    margin-bottom: 0;
    font-weight: 500;
  }
  .price {
    position: relative;
    padding-top: $pricing-card-price-padding-top;
    .number {
      padding: $pricing-card-number-padding;
      font-size: $pricing-card-number-font-size;
      font-weight: 300;
      &::before {
        position: absolute;
        margin-top: $pricing-card-number-before-margin-top;
        margin-left: $pricing-card-number-before-margin-left;
        font-size: $pricing-card-number-before-font-size;
        content: "$";
      }
      &::after {
        position: absolute;
        margin-top: $pricing-card-number-after-margin-top;
        font-size: $pricing-card-number-after-font-size;
        content: "/mo";
      }
    }
    .version {
      position: absolute;
      top: 0;
      width: 100%;
      padding: $pricing-card-version-padding;
      background-color: $pricing-card-version-bgc;
      border-radius: $md-card-border-radius $md-card-border-radius 0 0;
      box-shadow: $z-depth-1;
    }
  }
  .striped {
    padding: $pricing-card-striped-padding;
    li {
      margin-bottom: $pricing-card-striped-li-margin-bottom;
      border-bottom: 1px solid $pricing-card-striped-li-border-bottom-color;
    }
    &.green-striped {
      li {
        border-color: $pricing-card-green-striped-li-border-color;
      }
    }
    &.orange-striped {
      li {
        border-color: $pricing-card-orange-striped-li-border-color;
      }
    }
    &.purple-striped {
      li {
        border-color: $pricing-card-purple-striped-li-border-color;
      }
    }
    &.darker-striped {
      li {
        border-bottom: 1px solid $pricing-card-striped-li-border-bottom-color-darker;
      }
    }
  }
  .card-background {
    background-color: $pricing-card-dark-bgc;
    border-radius: 0 0 $md-card-border-radius $md-card-border-radius;
  }
}

// Additional pricing cards
.card {
  .card-circle {
    width: $pricing-card-circle-width;
    height: $pricing-card-circle-height;
    border: 2px solid $pricing-card-circle-border-color;
    border-radius: $pricing-card-circle-border-radius;
    .fas,
    .fab,
    .far {
      font-size: $pricing-card-circle-icon-font-size;
    }
  }
}
